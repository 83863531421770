'use client'

import dynamic from 'next/dynamic'

export const WebsiteDevtoolsClient = dynamic(
  () => import('./WebsiteDevTools').then((e) => e.WebsiteDevTools),
  {
    ssr: false,
  },
)
