'use client'

import { useEffect, useState } from 'react'
import Script from 'next/script'
import Cookie from 'js-cookie'

/*
  We add a Session Cookie via the googleCampaignCookie middleware
 If the cookie is present, we enable Google Analytics
*/
import { COOKIE_KEY } from '$/middlewares/googleCampaignCookie'

export function DynamicGoogleAnalytics() {
  // We only enable Google Analytics if the user comes from a Google Ad
  const [shouldEnableGA, setShouldEnableGA] = useState(false)

  useEffect(function checkGACookie() {
    const enableGA = Cookie.get(COOKIE_KEY)

    if (enableGA) {
      setShouldEnableGA(true)
    }
  }, [])

  if (!shouldEnableGA) return null

  return (
    <>
      <Script
        id="gtm-4"
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.GOOGLE_ANALYTICS_4_KEY}`}
        strategy="lazyOnload"
      />
      <Script
        id="gtm-4-data-layer"
        dangerouslySetInnerHTML={{
          __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${process.env.GOOGLE_ANALYTICS_4_KEY}');
            `,
        }}
      />
    </>
  )
}
