import(/* webpackMode: "eager" */ "/vercel/path0/app/_favicon/apple-touch-icon.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/app/_favicon/favicon-16x16.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/app/_favicon/favicon-32x32.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/app/_favicon/favicon.ico");
;
import(/* webpackMode: "eager" */ "/vercel/path0/app/_favicon/favicon.svg");
;
import(/* webpackMode: "eager", webpackExports: ["AppProviders"] */ "/vercel/path0/app/AppProviders.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DynamicGoogleAnalytics"] */ "/vercel/path0/app/DynamicGoogleAnalytics.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WebsiteDevtoolsClient"] */ "/vercel/path0/components/WebsiteDevTools/index.ts");
;
import(/* webpackMode: "eager" */ "/vercel/path0/css/tailwind.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/css/globals.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/Icons/iconsStyles.css");
;
import(/* webpackMode: "eager", webpackExports: ["SafariPolyfill"] */ "/vercel/path0/modules/polyfills.ts");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
