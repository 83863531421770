/**
 * This code is used in the backend and frontend,
 * so it has to be a separate file instead of on each model file.
 */
import type {
  PageDocument,
  AuthorDocument,
  ChangelogDocument,
} from '$/prismicio-types'
import { getPrismicLinkToDocument } from '$/modules/getPrismicLinkToDocument'

import { VALID_PRODUCTS, isValidProduct } from './prismicTypes'

import type { Product } from './prismicTypes'
import type { CarrierDocument } from './carrier/types'

function validateHref(href: string, docId: string) {
  // we compare the href with lowercase to make sure the canonical urls will match
  const lowercaseHref = href.toLowerCase()

  if (href !== lowercaseHref) {
    throw new Error(
      'The slug must be lowercase. ' +
        `You can fix the problem on Prismic: ${getPrismicLinkToDocument(
          docId,
        )}`,
    )
  }
}

export function getHrefFromBlogPostDoc(doc: {
  id: string
  data: { product: Product | 'no-product' | null; slug: string }
}) {
  const { data: { slug, product } = {} } = doc

  if (!product || product === 'no-product') {
    const href = `/blog/${slug}` as const

    validateHref(href, doc.id)

    return href
  }

  const href = `/${product}/blog/${slug}` as const

  validateHref(href, doc.id)

  return href
}

export function getHrefFromPageDoc(doc: PageDocument) {
  const { data: { product } = {} } = doc

  const slugWithSlash =
    !doc.data?.slug || doc.data.slug === '/' ? '' : `/${doc.data.slug}`

  if (!isValidProduct(product)) {
    if (!slugWithSlash) {
      return '/'
    }

    validateHref(slugWithSlash, doc.id)

    return slugWithSlash
  }

  const href = `/${product}${slugWithSlash}` as const

  validateHref(href, doc.id)

  return href
}

export function getHrefFromChangelogProduct(
  doc: ChangelogDocument,
  singleChangelogSlug?: string,
) {
  const { product } = doc.data

  if (isValidProduct(product)) {
    const href = `/${product}/product-updates${
      singleChangelogSlug ? `/${singleChangelogSlug.toLowerCase()}` : ''
    }` as const

    validateHref(href, doc.id)

    return href
  }

  return '/product-updates' as const
}

const SINGLE_CHANGELOG_HREF_REGEX = new RegExp(
  // eslint-disable-next-line no-useless-escape
  `^\/(${VALID_PRODUCTS.join('|')})\/product-updates\/[a-z]+(-[a-z]+)*$`,
)

/**
 * Tells if an `href` is from a single changelog page.
 */
export function isSingleChangelogHref(href: string) {
  return SINGLE_CHANGELOG_HREF_REGEX.test(href)
}

export function getHrefFromAuthorDoc(doc: AuthorDocument): string {
  const { data: { slug } = {} } = doc

  const href = `/blog/author/${slug}` as const

  validateHref(href, doc.id)

  return href
}

export function getHrefFromCarrierDoc(doc: CarrierDocument) {
  const {
    data: { slug },
  } = doc

  const href = `/package-tracker/${slug}` as const

  validateHref(href, doc.id)

  return href
}

export function getHrefFromCourierListPage(currentPage?: number | string) {
  if (currentPage === undefined) {
    return '/package-tracker/global-package-tracking' as const
  }

  return `/package-tracker/global-package-tracking/${currentPage}` as const
}

export function getHrefFromTrackingPage(queryParams?: URLSearchParams) {
  if (queryParams) {
    return `/package-tracker/tracking?${queryParams.toString()}` as const
  }

  return '/package-tracker/tracking' as const
}

export function getTermsHref() {
  return '/terms' as const
}

export function getPrivacyHref() {
  return '/privacy' as const
}

export function getHrefFromCategoryDoc(doc: {
  id: string
  data: { slug: string; product: Product }
}) {
  const { data: { slug, product } = {} } = doc

  const href = `/${product}/blog/${slug}` as const

  validateHref(href, doc.id)

  return href
}

export function getSearchHref<P extends Product = Product>(
  product: P,
  searchText?: string,
  page?: string | number,
): `/${P}/blog/search${string}`
export function getSearchHref(
  product: undefined,
  searchText?: string,
  page?: string | number,
): undefined
export function getSearchHref(
  product: Product | undefined,
  searchText?: string,
  page?: string | number,
) {
  if (!product) {
    return
  }

  const queryParams = new URLSearchParams()

  if (searchText) {
    queryParams.append('q', searchText)
  }

  if (page) {
    queryParams.append('page', String(page))
  }

  const queryParamsStr = queryParams.size ? `?${queryParams.toString()}` : ''

  return `/${product}/blog/search${queryParamsStr}`
}
