'use client'

import 'client-only'

const isSafari =
  typeof window !== 'undefined' &&
  navigator.userAgent.indexOf('Safari') !== -1 &&
  navigator.userAgent.indexOf('Chrome') === -1

/** Polyfills specifically for Safari */
async function insertSafariPolyfills() {
  // we need this one because even though Safari has ResizeObserver,
  // it doesn't support the `useBorderBoxSize` options
  const pkg = await import('@juggle/resize-observer')

  window.ResizeObserver = pkg.ResizeObserver
}

if (isSafari) {
  insertSafariPolyfills()
}

export function SafariPolyfill() {
  return null
}
