'use client'

import { Suspense, lazy } from 'react'
import { IntlProvider } from 'react-intl'
import { PrismicProvider } from '@prismicio/react'

import { useAmplitudeTracking } from '$/modules/hooks/useAmplitudeTracking'
import { linkResolver } from '$/services/prismic/linkResolver'

const PrismicPreview = lazy(() => import('$/components/PrismicPreview'))

type Props = {
  isPreview: boolean
  children: React.ReactNode
}

export function AppProviders(props: Props) {
  const { children, isPreview } = props

  // TODO: find a way to implement the correct locale
  const locale = 'en'

  useAmplitudeTracking()

  return (
    <IntlProvider locale={locale}>
      <PrismicProvider linkResolver={linkResolver}>
        {children}
        {isPreview && (
          <Suspense fallback={null}>
            <PrismicPreview />
          </Suspense>
        )}
      </PrismicProvider>
    </IntlProvider>
  )
}
