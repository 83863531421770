import { NextResponse, type NextRequest } from 'next/server'

/*
We need to enable Google Analytics if the user comes from a Google Ad.
To do this, we set a session cookie when the user comes from a Google Ad.
We recognize Google Ad users via the `gclid` search params
Search params values are not relevant, GA tracks them automatically
*/

// This key is accessed in the DynamicGoogleAnalytics component
export const COOKIE_KEY = 'enableGA'

export function googleCampaignCookie(request: NextRequest) {
  const { searchParams } = request.nextUrl
  const gclid = searchParams.get('gclid')

  const response = NextResponse.next()

  if (gclid) {
    const ninetyDaysInSeconds = 60 * 60 * 24 * 90

    response.cookies.set(COOKIE_KEY, 'true', { maxAge: ninetyDaysInSeconds })
  }

  return response
}
