/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * Removes any nullish property from `obj` or any inside object.
 * This function doesn't deal with circular references inside the object,
 * It will throw an error if you pass an object with it.
 */
export function deepClearNullishProperties<T>(
  obj: Record<string, any> | any[],
) {
  return JSON.parse(
    JSON.stringify(obj, function replacer(k, v) {
      return v == null ? undefined : v
    }),
  ) as T
}
