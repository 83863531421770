import type { DeepRequired } from '$/modules/DeepRequired'
import type { CarrierDocument } from '$/models/carrier/types'
import type { BlogPostDocument } from '$/models/blogPost/types'
import type {
  PageDocument,
  AuthorDocument,
  CategoryDocument,
  ChangelogDocument,
  SearchPageDocument,
} from '$/prismicio-types'
import {
  getSearchHref,
  getHrefFromPageDoc,
  getHrefFromAuthorDoc,
  getHrefFromCarrierDoc,
  getHrefFromCategoryDoc,
  getHrefFromBlogPostDoc,
  getHrefFromChangelogProduct,
  getHrefFromCourierListPage,
  getHrefFromTrackingPage,
} from '$/models/getHrefs'

import type { FilledContentRelationshipField } from '@prismicio/client'

// Link resolution rules
// Manages the url links to internal Prismic documents
export function linkResolver(doc: FilledContentRelationshipField) {
  if (!doc.data) {
    return '/'
  }

  const { type } = doc

  if (type === 'page') {
    return getHrefFromPageDoc(doc as unknown as PageDocument)
  }

  if (
    doc.type === 'article' &&
    typeof doc.data === 'object' &&
    doc.data &&
    'product' in doc.data &&
    'slug' in doc.data
  ) {
    return getHrefFromBlogPostDoc(
      doc as unknown as DeepRequired<
        BlogPostDocument,
        'data.product' | 'data.slug'
      >,
    )
  }

  if (doc.type === 'author') {
    return getHrefFromAuthorDoc(doc as unknown as AuthorDocument)
  }

  if (doc.type === 'category') {
    return getHrefFromCategoryDoc(
      doc as unknown as DeepRequired<
        CategoryDocument,
        'data.product' | 'data.slug'
      >,
    )
  }

  if (doc.type === 'changelog') {
    return getHrefFromChangelogProduct(doc as unknown as ChangelogDocument)
  }

  if (doc.type === 'package_tracker_carriers') {
    return getHrefFromCarrierDoc(doc as unknown as CarrierDocument)
  }

  if (doc.type === 'terms') {
    return '/terms'
  }

  if (doc.type === 'privacy') {
    return '/privacy'
  }

  if (doc.type === 'courier_list_page') {
    return getHrefFromCourierListPage()
  }

  if (doc.type === 'tracking_page') {
    return getHrefFromTrackingPage()
  }

  return '/'
}

export function previewLinkResolver(
  doc: Omit<FilledContentRelationshipField, 'url'>,
) {
  if (doc.type === 'search_page' && doc.data) {
    const href = getSearchHref(
      (doc as unknown as SearchPageDocument).data.product,
    )

    // We add a query string so the search page can give
    // something in the preview instead of just a not found page.
    return `${href}?q=how%20to`
  }

  return linkResolver(doc)
}
