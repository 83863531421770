'use-client'

import { useEffect } from 'react'
import * as Amplitude from '@amplitude/analytics-browser'
import { CampaignParser } from '@amplitude/analytics-client-common'
import { usePathname } from 'next/navigation'
import Cookie from 'js-cookie'

import config from '$/generated/a_b_test_settings.json'
import { deepClearNullishProperties } from '$/modules/deepClearNullishProperties'
import type { Setting } from '$/middlewares/abTestMiddleware'

import type { Event } from '@amplitude/analytics-types'

let isAmplitudeInitialized = false

export function useAmplitudeTracking() {
  const nextPathname = usePathname()

  useEffect(function initializeAmplitude() {
    if (process.env.AMPLITUDE_API_KEY == null) return

    if (isAmplitudeInitialized) return

    isAmplitudeInitialized = true

    Amplitude.init(process.env.AMPLITUDE_API_KEY as string, undefined, {
      defaultTracking: {
        pageViews: {
          eventType: 'Pageview',
          trackOn() {
            const { pathname } = window.location

            return (
              pathname.includes('teams') ||
              pathname.includes('route-planner') ||
              pathname.includes('package-tracker')
            )
          },
        },
      },
    })
  }, [])

  useEffect(
    function addToAmplitude() {
      if (process.env.AMPLITUDE_API_KEY == null) return

      // we check if server router and client path point to the same page to avoid race conditions and false positives
      if (nextPathname !== window.location.pathname) return

      Amplitude.add({
        name: 'Enhance tracking params',
        type: 'enrichment',
        setup: async function setup() {
          const getCampaignParams = await new CampaignParser().parse()
          const campaignParams =
            deepClearNullishProperties<Amplitude.Types.Campaign>(
              getCampaignParams,
            )

          const AmplitudeIdentify = new Amplitude.Identify()

          for (const param in campaignParams) {
            AmplitudeIdentify.set(
              param,
              campaignParams[param] as Amplitude.Types.ValidPropertyType,
            )
          }

          // setOnce events will only consider the first instance based on the device id and ignore following ones
          AmplitudeIdentify.setOnce('Landing page', {
            page_path: nextPathname,
          })

          Amplitude.identify(AmplitudeIdentify)

          return Promise.resolve()
        },
        execute: async function execute(context: Event) {
          let experimentConfigInfo = {}
          const setting = config.settings?.find((s: Setting) => {
            return s.originalPathname === nextPathname
          }) as unknown as Setting | undefined

          if (setting?.experimentName) {
            const { experimentName } = setting
            const experimentVariation = Cookie.get(setting.experimentName)

            experimentConfigInfo = {
              ExperimentName: experimentName,
              ExperimentVariation: experimentVariation,
            }
          }

          context.event_properties = {
            channel: 'CMS',
            ...experimentConfigInfo,
            // we need to match the CFT current params on all tracking events
            // from: https://github.com/amplitude/Amplitude-TypeScript/blob/890ec6695a8b25cd6988e9f7ae584d4ba2835f67/packages/plugin-page-view-tracking-browser/src/page-view-tracking.ts
            location: window?.location?.href ?? '',
            title: document?.title ?? '',
            ...context.event_properties,
          }

          return Promise.resolve(context)
        },
      })
    },
    [nextPathname],
  )
}
