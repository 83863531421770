export const VALID_PRODUCTS: readonly Product[] = [
  'teams',
  'route-planner',
  'package-tracker',
] as const

export function isValidProduct(
  product: string | null | undefined,
): product is Product {
  return VALID_PRODUCTS.includes(product as Product)
}

export type CustomType =
  | 'article'
  | 'author'
  | 'category'
  | 'navigation'
  | 'package_tracker_carriers'
  | 'page'
  | 'reusable'
  | 'blog_post_settings'
  | 'changelog'
  | 'courier_list_page'
  | 'privacy'
  | 'search_page'
  | 'terms'
  | 'tracking_page'
  | 'slice_group'
  | 'related_documents'

export type CustomTypeBlog = 'article' | 'category'

export type Product = 'teams' | 'route-planner' | 'package-tracker'

/**
 * Defines which type of model we have.
 * @value `'all-data'`: The model has all document's data.
 * @value `'document-field'`: The model has only the necessary
 * data to use as an attribute of something else, like
 * `blogPost.category`. We don't need the category's SEO data or the slices.
 */
export type ModelType = 'all-data' | 'document-field'

/**
 * Extension of `ModelType`, but with another value.
 * @value `'document-field-with-meta'`: Similar to `'document-field'`,
 * but with SEO Meta object. This is useful in the `BlogPost` type
 * because we need the metadata to generate the `BlogPost` breadcrumb.
 */
export type ModelTypeWithMeta = ModelType | 'document-field-with-meta'

/**
 * Tells if a document has the data defined or not.
 * @value `'defined'`: `document.data` is defined.
 * @value `'undefined'`: `document.data` is undefined.
 * @value `'default'`: `document.data` might be defined or undefined.
 */
export type DataDefinition = 'defined' | 'undefined' | 'default'

export type ExportDocData<D extends object, T extends CustomType> = {
  type: T
} & D
